<template>
  <div>
    <page-title title="Bulk Uploads"></page-title>
    <h5>
      Bulk Upload
      <span>(Select type of upload, and download template, or upload.)</span>
    </h5>
    <div class="action-buttons">
      <button
        type="button"
        class="btn btn-gray-light"
        @click="bulkExportModal = true">
        Download Template
        <i class="material-icons">
          cloud_download
        </i>
      </button>
      <button
        type="button"
        class="btn btn-primary ml-2"
        @click="showBulkUploadModal = true">
        Bulk Upload
      </button>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <span>Current job status as of: {{ lastRefreshTime }}</span>
        <button
          type="button"
          class="btn btn-primary ml-2"
          @click="getActiveJobStatus()">
          Get Job Status Data
        </button>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-6 py-2 mb-2">
        <textarea
          v-model="activeJobsList"
          readonly
          placeholder="No job info retrieved"
          cols="100"
          rows="5"
          class="form-control"></textarea>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="row">
      <div class="col-2">
        <v-select
          v-model="uploadedBy"
          :items="distinctUploadedBy"
          clearable
          label="Uploaded By">
        </v-select>
      </div>
      <div class="col-2">
        <v-select
          v-model="selectedBulkType"
          :items="bulkTypes"
          item-text="displayName"
          item-value="value"
          clearable
          label="Type">
        </v-select>
      </div>
      <div class="col-3">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto">
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="onOrAfterDate"
              label="On or After Date Uploaded"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <v-date-picker
            v-model="onOrAfterDate"
            :min="minOnOrAfterDate"
            :max="maxOnOrAfterDate || null"
            @input="dateMenu = false">
          </v-date-picker>
        </v-menu>
      </div>
      <div class="col-3">
        <div class="form-group search-group">
          <input
            v-model="search"
            type="text"
            class="form-control search-input"
            autocomplete="off"
            placeholder="File Name" />
          <i class="material-icons search-icon">
            search
          </i>
        </div>
      </div>
    </div>
    <bulk-list
      :on-or-after-date="onOrAfterDate"
      :uploaded-by="uploadedBy"
      :selected-bulk-type="selectedBulkType"
      :search="search">
    </bulk-list>

    <modal
      v-if="showBulkUploadModal"
      class="bulk-upload-modal-container"
      heading="Bulk Upload"
      :scrollable="false"
      @close="showBulkUploadModal = false">
      <div
        slot="body"
        class="bulk-upload-modal">
        <!--Types: globalvalues=1, bannerspecific=2, products=3, media=4, associations=5,
        Subtypes: English=1, French=2, Spanish=3, Rexel=4, Platt=5, Gexpro=6, RexelCanada=7, Attributes=8 , Media=9, Associations=10-->
        <div class="bulk-upload-options">
          <label><b><u>Global Values:</u></b></label>
          <button
            class="btn btn-primary"
            @click="bulkImport(1,1)">
            English
          </button>
          <button
            class="btn btn-primary"
            disabled="true"
            @click="bulkImport(1,3)">
            Spanish
          </button>
          <button
            class="btn btn-primary"
            disabled="true"
            @click="bulkImport(1,2)">
            French
          </button>
        </div>
        <div class="bulk-upload-options">
          <label><b><u>Banner Specific Fields:</u></b></label>
          <button
            class="btn btn-primary"
            @click="bulkImport(2,4)">
            Rexel
          </button>
          <button
            class="btn btn-primary"
            @click="bulkImport(2,5)">
            Platt
          </button>
          <button
            class="btn btn-primary"
            @click="bulkImport(2,6)">
            Gexpro
          </button>
          <button
            class="btn btn-primary"
            @click="bulkImport(2,7)">
            Rexel CA
          </button>
        </div>
        <div class="bulk-upload-options">
          <label><b><u>Attributes:</u></b></label>
          <button
            class="btn btn-primary"
            @click="bulkImport(3,8)">
            Attributes
          </button>
        </div>
        <div class="bulk-upload-options">
          <label><b><u>Media:</u></b></label>
          <button
            class="btn btn-primary"
            @click="bulkImport(4,9)">
            Media
          </button>
        </div>
        <div class="bulk-upload-options">
          <label><b><u>Associations:</u></b></label>
          <button
            class="btn btn-primary"
            @click="bulkImport(5,10)">
            Associations
          </button>
        </div>
        <div class="bulk-upload-options">
          <label><b><u>Product Matching:</u></b></label>
          <button
            class="btn btn-primary"
            @click="bulkImport(6,11)">
            Product Matching
          </button>
        </div>
      </div>
    </modal>
    <bulk-products
      :show-modal.sync="bulkImportBannerSpecific"
      :directions="true"
      title="Bulk Import - Banner Specific Values"
      :upload-subtype="bulkImportSubtype"
      :upload-type="bulkImportType"
      @addToList="addToList($event)">
      <div
        slot="directions"
        class="directions">
        <h6>Bulk Banner Specific Values Import Directions:</h6>
        <ol>
          <li>Click the 'Download Template' button then select 'Banner Specific' from the dropdown</li>
          <li>Configure the options that you wish to include in your template by clicking the toggle next to the option description</li>
          <li>Click the 'Export' button and the file will be downloaded automatically</li>
          <li>Fill out the template column data with the desired product information</li>
          <li>Ensure all data is in the correct columns</li>
          <li>Browse to the folder containing the edited product information spreadsheet and upload the file</li>
          <li>Click the 'Run Process' button to complete the import</li>
        </ol>
      </div>
    </bulk-products>
    <bulk-products
      :show-modal.sync="bulkImportGlobalValues"
      :directions="true"
      title="Bulk Import - Globals Values"
      :upload-subtype="bulkImportSubtype"
      :upload-type="bulkImportType"
      @addToList="addToList($event)">
      <div
        slot="directions"
        class="directions">
        <h6>Bulk Global Values Import Directions:</h6>
        <ol>
          <li>Click the 'Download Template' button then select 'Global Values' from the dropdown</li>
          <li>Configure the options that you wish to include in your template by clicking the toggle next to the option description</li>
          <li>Click the 'Export' button and the file will be downloaded automatically</li>
          <li>Fill out the template column data with the desired product information</li>
          <li>Ensure all data is in the correct columns</li>
          <li>Browse to the folder containing the edited product information spreadsheet and upload the file</li>
          <li>Click the 'Run Process' button to complete the import</li>
        </ol>
      </div>
    </bulk-products>
    <bulk-attributes
      :show-modal.sync="bulkImportAttributes"
      :directions="true"
      title="Bulk Import - Attributes"
      :upload-subtype="bulkImportSubtype"
      :upload-type="bulkImportType">
      <div
        slot="directions"
        class="directions">
        <h6>Bulk Attribute Import Directions:</h6>
        <ol>
          <li>Click the 'Download Template' button then select 'Attributes' from the dropdown</li>
          <li>Click the 'Export' button and the file will be downloaded automatically</li>
          <li>Fill out the template column data with the desired attribute information</li>
          <li>Ensure all data is in the correct columns</li>
          <li>Browse to the folder containing the edited attribute information spreadsheet and upload the file</li>
          <li>Click the 'Run Process' button to complete the import</li>
        </ol>
      </div>
    </bulk-attributes>
    <bulk-media
      :show.sync="bulkImportMedia"
      :directions="true"
      title="Bulk Import - Media"
      :upload-subtype="bulkImportSubtype"
      :upload-type="bulkImportType">
      <div
        slot="directions"
        class="directions">
        <h6>Bulk Media Import Directions:</h6>
        <ol>
          <li>Create a new folder and copy all the files you intend to upload into that folder.</li>
          <li>
            Download the BulkMedia_Template.csv file by clicking the "Download Template" button or from
            <a
              href="/assets/templates/BulkMedia_Template.csv"
              target="_blank">here</a>.
          </li>
          <li>Copy the BulkMedia_Template.csv file to the folder containing the media.</li>
          <li>Fill out the template column data with the desired product information and image file references (Include file name and extension for all files. [i.e image.jpg])</li>
          <li>Ensure all data and references are in the correct columns.</li>
          <li>Compress all files within the folder containing the template and media files to create a .zip folder. Zip should not contain any sub directory folders, only files.</li>
          <li>Browse to the folder containing the images and the edited ‘BulkMedia_Template.csv’ file.</li>
          <li>Check confirmation checkbox and process upload</li>
        </ol>
      </div>
    </bulk-media>
    <bulk-associations
      :show-modal.sync="bulkImportAssociations"
      :directions="true"
      title="Bulk Import - Associations"
      :upload-subtype="bulkImportSubtype"
      :upload-type="bulkImportType">
      <div
        slot="directions"
        class="directions">
        <h6>Bulk Associations Import Directions:</h6>
        <ol>
          <li>Click the 'Download Template' button then select 'Associations' from the dropdown</li>
          <li>Click the 'Download Associations Template' button and the file will be downloaded</li>
          <li>Fill out the template column data with the desired association information</li>
          <li>Ensure all data is in the correct columns</li>
          <li>Browse to the folder containing the edited association information spreadsheet and upload the file</li>
          <li>Click the 'Run Process' button to complete the import</li>
        </ol>
      </div>
    </bulk-associations>
    <bulk-associations
      v-if="!$store.getters.isProductionEnvironment"
      :show-modal.sync="bulkImportProductMatches"
      :directions="true"
      title="Bulk Import - Product Matches"
      :upload-subtype="bulkImportSubtype"
      :upload-type="bulkImportType">
      <div
        slot="directions"
        class="directions">
        <h6>Bulk Product Matches Import Directions:</h6>
        <ol>
          <li>Click the 'Download Template' button then select 'Product Matching' from the dropdown</li>
          <li>Click the 'Download Product Matching Template' button and the file will be downloaded</li>
          <li>Fill out the template column data with the desired association information</li>
          <li>Ensure all data is in the correct columns</li>
          <li>Browse to the folder containing the edited product matching information spreadsheet and upload the file</li>
          <li>Click the 'Run Process' button to complete the import</li>
        </ol>
      </div>
    </bulk-associations>

    <bulk-export
      :show-modal.sync="bulkExportModal"
      :products="null"
      :by-type="true"
      label="Type/Sections to include"
      title="Bulk Import Template Generator"
      @export="downloadCSV($event)"></bulk-export>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import TitleComponent from 'components/pageTitle.vue'
import Modal from 'components/modals/basic.vue'
import BulkImportProducts from 'components/bulk/Import'
import BulkExport from 'components/bulk/Export'
import BulkImportMedia from '../media/mediaList/bulk.vue'
import BulkList from './components/BulkList'
import moment from 'moment'
import downloadFileFromApi from '@/utils/downloadFileService'

export default {
  name: 'BulkUploadsList',
  filters: {
    sanitizeClass: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()
    }
  },
  components: {
    'page-title': TitleComponent,
    modal: Modal,
    'bulk-products': BulkImportProducts,
    'bulk-attributes': BulkImportProducts,
    'bulk-media': BulkImportMedia,
    'bulk-associations': BulkImportProducts,
    'bulk-export': BulkExport,
    BulkList
  },
  data () {
    return {
      lastRefreshTime: 'N/A',
      activeJobsList: [],
      bulkExportModal: false,
      bulkImportMedia: false,
      bulkImportAttributes: false,
      bulkImportBannerSpecific: false,
      bulkImportGlobalValues: false,
      bulkImportAssociations: false,
      bulkImportProductMatches: false,
      showBulkUploadModal: false,
      bulkUploads: [],
      sortCol: '',
      sortAsc: false,
      search: '',
      bulkUploadsIndex: 0,
      dataTableOptions: {
        itemsPerPage: 50
      },
      status: 'pending',
      bulkImportType: -1,
      bulkImportSubtype: -1,
      uploadedBy: null,
      bulkTypes: [
        { displayName: 'Attributes', value: ' Attributes' },
        { displayName: 'CrossSell', value: 'CrossSell' },
        { displayName: 'English', value: ' English' },
        { displayName: 'Gexpro', value: ' Gexpro' },
        { displayName: 'Media', value: ' Media' },
        { displayName: 'Platt', value: ' Platt' },
        { displayName: 'ProductMatch', value: 'ProductMatch' },
        { displayName: 'Rexel', value: ' Rexel' }
      ],
      selectedBulkType: null,
      onOrAfterDate: null,
      minOnOrAfterDate: null,
      maxOnOrAfterDate: null,
      numberOfDaysBack: 90,
      dateMenu: null,
      tableHeader: [
        { text: 'Uploaded', value: 'dateSubmitted' },
        { text: 'Uploaded By', value: 'uploadedBy' },
        { text: 'File Name', value: 'filename' },
        { text: 'Type', value: 'type' },
        { text: '', value: 'download' },
        { text: 'Status', value: 'status' },
        { text: 'Details', value: 'statusInfo' }
      ],
      loadingData: false
    }
  },
  computed: {
    distinctUploadedBy () {
      return [...new Set(this.bulkUploads.map(x => x.uploadedBy))].sort()
    }
  },
  watch: {
    $route: 'getAllBulkUploadsFiles'
  },
  mounted () {
    // Set displayed Filter Date based on numberOfDaysBack
    this.maxOnOrAfterDate = new Date().toISOString().slice(0, 10)
    const dateOffset = (24 * 60 * 60 * 1000) * this.numberOfDaysBack
    const myDate = new Date()
    myDate.setTime(myDate.getTime() - dateOffset)
    this.onOrAfterDate = myDate.toISOString().slice(0, 10) // Just get the Date Part
    this.minOnOrAfterDate = this.onOrAfterDate
  },
  methods: {
    getActiveJobStatus () {
      this.activeJobsList = ''
      this.lastRefreshTime = moment().format('MM/DD/YYYY HH:mm:ss')
      axios.post('/api/bulk/pendingjobstatus')
        .then(res => {
          if (res.data.length !== 0) {
            this.activeJobsList = res.data.join('')
          }
        }).catch(e => {
        })
    },
    downloadCSV (options) {
      this.bulkExportModal = false
      const params = {
        ...options,
        ids: (options.ids) ? options.ids.join() : '',
        template: (!options.ids)
      }

      const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      const url = `${process.env.VUE_APP_API_BASE_URL}/api/bulk/export?${queryString}`
      downloadFileFromApi(url)
    },
    downloadFile (record, errorsOnly = false) {
      const bulkType = record.type === 'CrossSell' ? 'Associations' : record.type
      const queryParam = errorsOnly ? `?errorsOnly=true&type=${bulkType}` : ''
      downloadFileFromApi(`/api/bulk/${record.uploadId}${queryParam}`)
    },
    bulkImport (type, subtype) {
      this.bulkImportSubtype = subtype
      this.bulkImportType = type
      this.showBulkUploadModal = false
      if (type === 1) {
        this.bulkImportGlobalValues = true
      }
      if (type === 2) {
        this.bulkImportBannerSpecific = true
      }
      if (type === 3) {
        this.bulkImportAttributes = true
      }
      if (type === 4) {
        this.bulkImportMedia = true
      }
      if (type === 5) {
        this.bulkImportAssociations = true
      }
      if (type === 6) {
        this.bulkImportProductMatches = true
      }
    },
    escapeRegExChars (value) {
      return value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    },
    addToList (item) {
      this.bulkUploads.push(item)
    },
    resolveInvalidRecord (uploadId) {
      axios.patch(`/api/bulk/${uploadId}/resolve`)
        .then(() => {
          const index = this.bulkUploads.findIndex(i => i.uploadId === uploadId)
          if (index > -1) {
            const uploadRecord = this.bulkUploads[index]
            this.$set(this.bulkUploads, index, { ...uploadRecord, status: 'Resolved' })
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    isError (bulkUploads) {
      if (!bulkUploads.statusInfo) {
        return false
      }
      const re = /[1-9]\d* errors/
      return bulkUploads.statusInfo.match(re)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/bootstrap.scss";
@import "../../assets/scss/extra/mixins/flexbox.scss";
@import "../../assets/scss/bootstrap-custom-variables.scss";
h5 {
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 1rem;
  span {
    color: #999;
    font-style: oblique;
  }
}
.action-buttons {
  margin-bottom: 1rem;
  .btn {
    font-size: 0.85rem;
    padding: 0.55rem 1.25rem;
    .material-icons {
      position: relative;
    }
  }
}
::v-deep .bulk-upload-modal-container {
  .modal-container {
    @media (min-width: 860px) {
    // override width
      width: 860px;
    }
  }
}
.bulk-upload-modal {
    @include flexbox;
    @include justify-content(space-between);
  .bulk-upload-options {
    @include flexbox;
    flex-direction: column;
  }
  .btn {
    font-size: 14px;
    margin-bottom: 1rem;
    padding: 0.75rem 1.25rem;
  }
}
.add-new-btn {
  padding: 0 1rem; // hidden until we are ready to add create..
  .btn {
    padding: 0.5rem 2rem;
  }
}

.bulkUploads-nav {
  @include flexbox;
  @include justify-content(space-between);

  .btn-nav {
    @include flex(1, 1, 75%);

    .router-link-exact-active {
      @extend .btn-primary;
    }
  }
}

.directions {
  text-align: left;
  margin: 1.5rem 0 1rem;
  a {
    font-weight: 700;
  }
  h6 {
    margin-bottom: 0.75rem;
  }
  ol {
    padding-left: 1rem;
    font-size: 0.8125rem;
    margin-bottom: 0;
    li {
      padding-left: 1rem;
    }
  }
}

.resolve-dropdown {
  .dropdown-toggle {
    background: transparent;
    font-size: 0.8125rem;
    padding: 0;
  }

  .dropdown-menu {
    padding: 0;
    min-width: 6rem;
    left: -29px !important;
    box-shadow: 2px 2px 3px #888888;
  }

  .dropdown-item {
    font-size: 0.8125rem;
    padding: 10px;
  }
}

.search-group {
  position: relative;
  .search-input {
    width: 100%;
    font-size: 0.875rem;
    color: #000;
    line-height: 1.42857143;
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    &:focus {
      border-color: $brand-primary;
      & ~ i {
        -webkit-transition: all ease-in-out 0.15s,
          -webkit-box-shadow ease-in-out 0.15s;
        transition: all ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        color: $brand-primary;
        opacity: 1;
      }
    }
  }
  .search-icon {
    position: absolute;
    font-size: 1.5rem;
    top: 0.5rem;
    right: 0.5rem;
    opacity: 0.4;
    padding: 0.25rem;
    &:hover {
      cursor: pointer;
      -webkit-transition: all ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
      transition: all ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      color: $brand-primary;
      opacity: 1;
    }
  }
}

.bulkUploads-col-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 18.5rem;

  label {
    margin: 0;
  }
}

.tooltip {
  &.info {
    .tooltip-inner {
      border-radius: 0px;
      background-color: #4c5159 !important;
    }
  }
}
</style>
