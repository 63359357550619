<template>
  <div class="pt-3">
    <h4 v-if="isDashboard">
      Bulk Uploads
    </h4>
    <v-data-table
      v-if="!loadingData"
      :headers="tableHeader"
      :items="bulkUploadsFiltered"
      :options="dataTableOptions"
      :loading="loadingData"
      :hide-default-footer="isDashboard"
      :footer-props="{
        'items-per-page-options': [10, 25, 50]
      }"
      dense
      fixed-header
      light
      loading-text="Loading... Please wait">
      <template #item.dateSubmitted="{ item }">
        {{ formattedUploadedDate(item?.dateSubmitted) }}
      </template>
      <template #item.uploadedBy="{ item }">
        {{ formattedUploadBy(item.uploadedBy) }}
      </template>
      <template #item.filename="{ item }">
        {{ item.filename }}
      </template>
      <template #item.download="{ item }">
        <a
          @click="downloadFile(item)">
          <i class="material-icons cloud-download">
            cloud_download
          </i>
        </a>
      </template>
      <template #item.status="{ item }">
        <div v-if="item.status === 'Invalid'">
          <div class="resolve-dropdown">
            <button
              id="dropdownMenuButton"
              type="button"
              class="btn dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <span :class="'status-' + item.status | sanitizeClass">
                {{ item.status }}
              </span>
            </button>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton">
              <button
                class="dropdown-item"
                type="button"
                @click="resolveInvalidRecord(item.uploadId)">
                Resolve
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <span :class="'status-' + item.status | sanitizeClass">
            {{ item.status }}
          </span>
        </div>
      </template>
      <template #item.statusInfo="{ item }">
        <div style="white-space: nowrap;">
          {{ item.statusInfo }}
          <a
            v-if="isError(item)"
            @click="downloadFile(item, true)">
            <i class="material-icons cloud-download icon-red">
              cloud_download
            </i>
          </a>
        </div>
      </template>
    </v-data-table>
    <div
      class="spinner"
      :class="{'show-spinner': showSpinner}"></div>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import downloadFileFromApi from '@/utils/downloadFileService'
import { format, parseISO } from 'date-fns'

export default {
  name: 'BulkList',
  filters: {
    sanitizeClass: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()
    }
  },
  props: {
    isDashboard: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedBulkType: {
      type: String,
      required: false
    },
    onOrAfterDate: {
      type: String,
      required: false
    },
    uploadedBy: {
      type: String,
      required: false
    },
    search: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      tableHeader: [
        { text: 'Uploaded', value: 'dateSubmitted' },
        { text: 'Uploaded By', value: 'uploadedBy' },
        { text: 'File Name', value: 'filename' },
        { text: 'Type', value: 'type' },
        { text: '', value: 'download' },
        { text: 'Status', value: 'status' },
        { text: 'Details', value: 'statusInfo' }
      ],
      dataTableOptions: {
        itemsPerPage: this.isDashboard ? 5 : 10,
        sortBy: ['dateSubmitted'],
        sortDesc: [true]
      },
      bulkUploads: [],
      showSpinner: true,
      numberOfDaysBack: 90,
      loadingData: true
    }
  },
  computed: {
    bulkUploadsFiltered () {
      if (this.isDashboard) {
        return this.bulkUploads.slice(0, 5)
      }

      let list = []
      list = this.bulkUploads

      if (this.search) {
        list = this.bulkUploads.filter((record, index) => {
          return record.filename.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        })
      }

      if (this.uploadedBy) {
        list = list.filter((record, index) => {
          return (record.uploadedBy.indexOf(this.uploadedBy) > -1)
        })
      }

      if (this.selectedBulkType) {
        list = list.filter((record, index) => {
          return (record.type.indexOf(this.selectedBulkType) > -1)
        })
      }

      if (this.onOrAfterDate) {
        const onOrAfterDateSplit = this.onOrAfterDate.split('-')
        const targetDate = new Date(onOrAfterDateSplit[0], onOrAfterDateSplit[1], onOrAfterDateSplit[2])
        list = list.filter((record, index) => {
          const splitDateUploaded = record.dateSubmitted.split('T')[0].split('-')
          const recordDateUploaded = new Date(splitDateUploaded[0], splitDateUploaded[1], splitDateUploaded[2])
          return (recordDateUploaded >= targetDate)
        })
      }
      return list
    }
  },
  created () {
    this.getAllBulkUploadsFiles()
  },
  methods: {
    getAllBulkUploadsFiles () {
      this.loadingData = true
      axios.get(`/api/bulk/validations?numberOfDaysBack=${this.numberOfDaysBack}`)
        .then(res => {
          this.bulkUploads = res.data
        })
        .finally(() => {
          this.showSpinner = false
          this.loadingData = false
        })
    },
    downloadFile (record, errorsOnly = false) {
      const bulkType = record.type === 'CrossSell' ? 'Associations' : record.type
      const queryParam = errorsOnly ? `?errorsOnly=true&type=${bulkType}` : ''
      downloadFileFromApi(`/api/bulk/${record.uploadId}${queryParam}`)
    },
    isError (bulkUploads) {
      if (!bulkUploads.statusInfo) {
        return false
      }
      const re = /[1-9]\d* errors/
      return bulkUploads.statusInfo.match(re)
    },
    formattedUploadedDate (dateTime) {
      return format(parseISO(dateTime), 'dd-MMM HH:mm a').toLocaleUpperCase()
    },
    formattedUploadBy (uploadedBy) {
      const splitName = uploadedBy.split(' ')
      return `${splitName[0]} ${splitName[1].substring(0, 1)}`
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/bootstrap.scss";
@import "../../../assets/scss/extra/mixins/flexbox.scss";
@import "../../../assets/scss/bootstrap-custom-variables.scss";
.status {
  &-valid {
    color: #00a14d;
  }
  &-pending-approval {
    color: rgb(255, 136, 0);
  }
  &-invalid {
    color: #a00;
  }
}

.cloud-download {
  font-size: 24px;
}

.icon-red {
  color: $brand-danger;
}
</style>
