<template>
  <div class="card-block">
    <div class="row">
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Rexel Item Number
          </label>
          <input
            v-model="product.productId"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Rexel Price Line
          </label>
          <input
            v-model="product.mfrCode"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Rexel SKU
          </label>
          <input
            v-model="product.sku"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Life Cycle
          </label>
          <select
            v-model="product.activeStatusCode"
            class="form-control"
            :disabled="isReadOnly">
            <option value="">
              --- Choose Lifecycle ---
            </option>
            <option value="1">
              Active
            </option>
            <option value="0">
              Inactive
            </option>
            <option value="2">
              Obsolete
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            UOM
          </label>
          <input
            v-model="product.uom"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Minimum Order Quantity
          </label>
          <input
            v-model="product.minOrdQty"
            type="number"
            class="form-control hide-arrows"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Vendor Group
          </label>
          <input
            v-model="product.vendorGroup"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Buy Line
          </label>
          <input
            v-model="product.buyLine"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
    </div>
    <v-row>
      <v-col :cols="3">
        <v-select
          v-model="product.showOnWeb"
          v-tooltip="'Not set interpreted as yes for Platt and no for others'"
          class="mb-2"
          :items="showOnWebItems"
          :disabled="isReadOnly"
          hide-details
          outlined
          dense
          label="Show On Web">
        </v-select>
      </v-col>
      <v-col>
        <label class="custom-control custom-checkbox ml-3">
          <input
            v-model="product.visibleOnlyToCustomers"
            type="checkbox"
            class="custom-control-input"
            :disabled="isReadOnly" />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">
            Only Visible To Customers
          </span>
        </label>
      </v-col>
      <v-col>
        <label class="custom-control custom-checkbox">
          <input
            v-model="product.callForPricing"
            type="checkbox"
            class="custom-control-input"
            :disabled="isReadOnly" />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">
            Call For Pricing
          </span>
        </label>
      </v-col>
      <v-col>
        <label
          v-tooltip="'This field is synced from Eclipse'"
          class="custom-control custom-checkbox">
          <input
            v-model="product.cuttable"
            type="checkbox"
            class="custom-control-input"
            disabled />
          <span
            class="custom-control-indicator color-override"></span>
          <span
            class="custom-control-description"
            style="color:black;">
            Cuttable
          </span>
        </label>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PermissionsMixin from 'mixins/Permissions.mixin'

export default {
  props: {
    product: {
      type: Object
    }
  },
  data () {
    return {
      vendorGroupOptions: ['RW_COMP'],
      showOnWebItems: [
        {
          text: 'Not set',
          value: null
        },
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ]
    }
  },
  computed: {
    isReadOnly () {
      return !this.$store.getters.hasPermissionForBanner(PermissionsMixin.data.Roles.contributor, 2)
    }
  },
  watch: {
    product: {
      handler () {
        this.$emit('bannerPropChange', 'rexel')
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
  .custom-control-input:disabled ~ .color-override {
    background-color:#ddd;
  }

  .custom-control-input:disabled:checked ~ .color-override {
    background-color: #00416a;
  }

</style>
